
























import { computed, defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { Pages } from '@/inc/types'
// import { parseRoute } from '@/inc/utils'

import Adndw from './Adndw.vue'
import Contact from './Contact.vue'
import Culture from './Culture.vue'
import DefaultTemplate from './DefaultTemplate.vue'
import Gouvernance from './Gouvernance.vue'
import Jobs from './Jobs.vue'
import Missions from './Missions.vue'
import Organisation from './Organisation.vue'
import Presse from './Presse.vue'
// Import Sub from './pages/Sub.vue'
import Profiles from './Profiles.vue'

const pages: Pages = {
  /* eslint-disable */
  'adn-dw': Adndw,
  contact: Contact,
  culture: Culture,
  'default-template': DefaultTemplate,
  governance: Gouvernance,
  jobs: Jobs,
  missions: Missions,
  organisation: Organisation,
  press: Presse,
  // sub: Sub,
  profiles: Profiles,
  /* eslint-enable */
}

export default defineComponent({
  name: 'VPage',
  setup() {
    // const { api, endpoint, resource } = parseRoute(ctx.root.$route)

    const { template } = useGetters(['template'])

    const slug = computed(() => template.value)
    const component = computed(
      () => pages[(slug.value as unknown) as string] || DefaultTemplate
    )

    // console.log('Template', useGetters(['slug']))
    // console.log('page', template, slug, component)
    // console.log('API:URL', `${api}/${endpoint}/${resource}`)

    return {
      component,
    }
  },
})
